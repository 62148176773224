import React from "react";

import API from '../core/api'

var TenantsStateContext = React.createContext();
var TenantsDispatchContext = React.createContext();

function tenantsReducer(state, action) {
  switch (action.type) {
    case "TENANTS_FETCH_SUCCESS":
      return { ...state, tenants: action.data };
    case "TENANTS_FETCH_FAILURE":
      return { ...state, errors: action.data };
    case "TENANT_SET":
        return { ...state, tenant: action.data.name, tenantData: action.data };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function TenantsProvider({ children }) {
  var [state, dispatch] = React.useReducer(tenantsReducer, {
    tenants: [],
    tenant: JSON.parse(localStorage.getItem('id_tenant')) && JSON.parse(localStorage.getItem('id_tenant')).name,
    tenantData:  JSON.parse(localStorage.getItem('id_tenant'))
  });

  return (
    <TenantsStateContext.Provider value={state}>
      <TenantsDispatchContext.Provider value={dispatch}>
        {children}
      </TenantsDispatchContext.Provider>
    </TenantsStateContext.Provider>
  );
}

function useTenantsState() {
  var context = React.useContext(TenantsStateContext);
  if (context === undefined) {
    throw new Error("useTenantsState must be used within a TenantsProvider");
  }
  return context;
}

function useTenantsDispatch() {
  var context = React.useContext(TenantsDispatchContext);
  if (context === undefined) {
    throw new Error("useTenantsDispatch must be used within a TenantsProvider");
  }
  return context;
}



const getTenants = async (dispatch) => {
  try {
    const token = localStorage.getItem('id_token')
    const response = await API.getTenants(token)
    
    dispatch({ type: 'TENANTS_FETCH_SUCCESS', data: response.data })
  } catch (err) {
    dispatch({ type: "TENANTS_FETCH_FAILURE", data: err.response.data });
  }
}

const selectTenant = (dispatch, tenant) => {
  localStorage.setItem('id_tenant', JSON.stringify(tenant))
  dispatch({ type: 'TENANT_SET', data: tenant })
}


export { TenantsProvider, useTenantsState, useTenantsDispatch, getTenants, selectTenant };