import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

// context
import { useDocumentsDispatch, getDocuments, useDocumentsState } from "../../context/DocumentsContext";

// components
import PageTitle from "../../components/PageTitle/PageTitle";

export default function Documents(props) {
  // global
  var documentsDispatch = useDocumentsDispatch();
  var documentsState = useDocumentsState();

  // local
  const [searchText, setSearchText] = useState('');
  const [numberOfRows, setNumberOfRows] = useState(15);

  useEffect(() => {
    getDocuments(documentsDispatch, numberOfRows, '', '', 'createdAt', 'DESC', 'All (default)', 1)
  }, [numberOfRows, documentsDispatch])

  return (
    <>
      <PageTitle title="Documents" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {documentsState.errors && Object.keys(documentsState.errors).length !== 0 && documentsState.errors.constructor === Object ? <Typography color="secondary">{documentsState.errors.message}</Typography> : (
          <MUIDataTable
            data={documentsState.data}
            columns={[
              { name: "title", label: "Тitle"},
              { name: "_id", label: "ID"},
              { name: "status", label: "Status" },
              { name: "createdAt", label: "Created at", options: {
                customBodyRender: (value) => {
                return value && (new Date(value).toLocaleDateString('en-EN', {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                }) + ' - ' + new Date(value).toLocaleString('en-EN', {
                  timeZone: 'Europe/Sofia',
                  hour: 'numeric',
                  minute: 'numeric',
                }))}
              } },
              { name: "expirationDate", label: "Expiration date",
              options: {
                customBodyRender: (value) => {
                return value && (new Date(value).toLocaleDateString('en-EN', {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                }) + ' - ' + new Date(value).toLocaleString('en-EN', {
                  timeZone: 'Europe/Sofia',
                  hour: 'numeric',
                  minute: 'numeric',
                }))}
              }
              },
              { name: "createdBy", label: "Created By",  options: { customBodyRender: (value) => { return value.email }}},
            ]}
            options={{
              onRowClick: (rowData) => {
                props.history.push(`/app/document/${rowData[1]}`)
              },
              selectableRowsHideCheckboxes: false,
              count:  documentsState.totalCount,
              rowsPerPage: numberOfRows,
              selectableRows: 'none',
              onChangeRowsPerPage: (_numberOfRows) => {
                setNumberOfRows(_numberOfRows)
                getDocuments(
                  documentsDispatch,
                  _numberOfRows,
                  '',
                  searchText,
                  'createdAt',
                  'DESC',
                  'All (default)',
                  documentsState.page,
                )
              },
              onSearchChange: (_searchText) => {
                setSearchText(_searchText)
                getDocuments(
                  documentsDispatch,
                  numberOfRows,
                  '',
                  _searchText,
                  'createdAt',
                  'DESC',
                  'All (default)',
                  documentsState.page,
                )
              },
              onChangePage: (page) => {
                if (page >= documentsState.page) {
                  getDocuments(
                    documentsDispatch,
                    numberOfRows,
                    '',
                    searchText,
                    'createdAt',
                    'DESC',
                    'All (default)',
                    page + 1,
                  )
                }
              },
            }}
          />
          )}
        </Grid>
      </Grid>
    </>
  );
}
