import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  dashedBorder: {
    border: "1px dashed",
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  textIdentifier: {
    color:"#2c3642b3",
    fontSize:"0.875rem"
  },
  textTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  participantBox: {
    backgroundColor: 'white',
    padding: theme.spacing(1),
    marginRight: theme.spacing(2)
  },
  participantImage: {
    marginRight: theme.spacing(2),
  }
}));