import React, { useEffect } from "react";
import { Grid, Box } from "@material-ui/core";

// components
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";

// context
import { useUsersDispatch, getUser, useUsersState } from "../../context/UsersContext";
import { useTenantsState } from "../../context/TenantsContext";

// styles
import useStyles from "./styles";

export default function UserPage(props) {
  const classes = useStyles();

  const id = props.match.params.id;
  const tenant = props.history.location.state && props.history.location.state.tenant;
  
  // global
  var usersDispatch = useUsersDispatch();
  var { user } = useUsersState();
  const tenantState = useTenantsState();


  useEffect(() => {
    getUser(usersDispatch, id, tenant)
  }, [usersDispatch, id, tenant])

  return (
    <>
      <PageTitle title="User" />
      
      {user && Object.keys(user).length !== 0 && user.constructor === Object && <Grid item spacing={2}>

                  <Box display="inline-flex" flexDirection="column" key={`${user.name}-avatar`}>
                    <Box display="flex" alignItems="center">

                      <img className={classes.participantImage} src={user.avatar}  alt={`${user.email} avatar`} width='20px' height='20px' />
                      <Box display="flex" flexDirection="column"> 
                      <Typography variant="p">{user.name}</Typography>
                      <Typography variant="p">{user.email}</Typography>
                      </Box>
                    </Box>
                    <Box mb={3}>

                    { user.phone && <Box mt={1}>
                      <Typography variant="p">
                        phone: {user.phone}
                      </Typography>
                    </Box> }
                    {user.pin && <Box mt={1} mb={1}>
                      <Typography variant="p">
                        pin: {user.pin}
                      </Typography>
                    </Box>}
                    {user.idCardNumber && <Box mt={1} mb={1}>
                      <Typography variant="p">
                        ID card number: {user.idCardNumber}
                      </Typography>
                    </Box>}
                    </Box>

                    <Box mb={3}>
                      <Box>
                        <Typography variant="p">
                          from {tenant || tenantState.tenant} tenant
                        </Typography>
                      </Box>
                      {user.entities && user.entities.map(entity => (
                          <>
                        <Box mt={1}>
                          <Typography variant="p">
                            tenant ID: {entity.ref}
                          </Typography> 
                        </Box>
                        <Box mt={1}>
                          <Typography variant="p">
                            role: {entity.role}
                          </Typography>
                        </Box>
                        {entity.division && (
                          <Box mt={1}>
                            <Typography variant="p">
                              division: {entity.division}
                            </Typography>
                          </Box>
                        )}
                        {entity.position && (

                          <Box mt={1}>
                            <Typography variant="p">
                              position: {entity.position}
                            </Typography>
                          </Box>
                        )}
                      </>
                        )
                      )}
                    </Box>
                    <Box>
                      <Box mt={1}>
                        <Typography variant="p">
                          status: {user.status}
                        </Typography>
                      </Box>
                      <Box mt={1}>
                        <Typography variant="p">
                          user suspended status: {user.suspended.toString()}
                        </Typography>
                      </Box>
                      <Box mt={1}>
                        <Typography variant="p">
                          idValid: {user.idValid.toString()}
                        </Typography>
                      </Box>
                      {user.idValidationStarted && (

                        <Box mt={1}>
                        <Typography variant="p">
                          idValidationStarted: {' '}
                          {new Date(user.idValidationStarted).toLocaleDateString('en-EN', {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric',
                          })} - {new Date(user.idValidationStarted).toLocaleString('en-EN', {
                            timeZone: 'Europe/Sofia',
                            hour: 'numeric',
                            minute: 'numeric',
                          })}
                        </Typography>
                      </Box>
                      )}
                    </Box>
                      <Box mt={1}>
                        <Typography variant="p">
                          idValidationFinished: {user.idValidationFinished.toString()}
                        </Typography>
                      </Box>
                      <Box mt={1}>
                        <Typography variant="p">
                          emailValidated: {user.emailValidated.toString()}
                        </Typography>
                      </Box>
                      <Box mt={1}>
                        <Typography variant="p">
                          phoneValidated: {user.phoneValidated.toString()}
                        </Typography>
                      </Box>
                    <Box>
                     
                    </Box>
                  </Box>
            </Grid>
    }
    </>
  );
}
