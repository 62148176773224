import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

// context
import { useUsersDispatch, getUsers, useUsersState } from "../../context/UsersContext";

// components
import PageTitle from "../../components/PageTitle";

export default function Users(props) {
  // global
  var usersDispatch = useUsersDispatch();
  var usersState = useUsersState();

  const [searchText, setSearchText] = useState('');
  const [numberOfRows, setNumberOfRows] = useState(15);

  useEffect(() => {
    getUsers(usersDispatch, numberOfRows, '', '', 'createdAt', 'DESC', 'All (default)', 0)
  }, [usersDispatch, numberOfRows])

  const renderUserStatus = (status) => {
    if (status === 'active') {
      return (
        <Box padding={1} borderRadius={4} bgcolor='rgba(21, 151, 21, 0.16)' color='#159715'>
          {status}
        </Box>
      )
    }
    if (status === 'invited') {
      return (
        <Box padding={1} borderRadius={4} bgcolor='rgba(246, 197, 16, 0.16)' color='#F6C510'>
          {status}
        </Box>
      )
    }
    if (status === 'pending-invitation') {
      return (
        <Box padding={1} borderRadius={4} bgcolor='rgba(246, 197, 16, 0.16)' color='#F6C510'>
          Invited
        </Box>
      )
    }
    if (status === 'draft') {
      return (
        <Box padding={1} borderRadius={4} bgcolor='rgba(246, 126, 16, 0.16)' color='#F67E10'>
          {status}
        </Box>
      )
    }
  }

  const canUserSign = (grants) => {
    let canSign = false
    if (!grants || !grants.length) return false
    for (let i = 0; i < grants.length; i++) {
      if (grants[i].type === 'sign') {
        if (
          grants[i].expires &&
          new Date(grants[i].expires).valueOf() < new Date().valueOf()
        ) {
          canSign = false
        } else {
          if (grants[i].administrativeDocument) {
            canSign = {
              hasPoA: true,
              documentId: grants[i].administrativeDocument,
            }
            if (grants[i].expires) {
              canSign = { ...canSign, expires: grants[i].expires }
            }
          } else {
            canSign = {
              hasPoA: false,
            }
          }
          break
        }
      }
    }
    return canSign
  }

  return (
    <>
      <PageTitle title="Users" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
        {usersState.errors && Object.keys(usersState.errors).length !== 0 && usersState.errors.constructor === Object ? <Typography color="secondary">{usersState.errors.message}</Typography> : (
          <MUIDataTable
            data={usersState.data}
            columns={[
              { name: "email", label: "Email"},
              { name: "name", label: "Name"},
              { name: "status", label: "Status", options: { customBodyRender: (value) => { return renderUserStatus(value) }}},
              { name: "phone", label: "Phone"},
              { name: "_id", label: "ID"},
              { name: "idValid", label: "IdValid", options: { customBodyRender: (value) => { return value.toString() }}},
              { name: "idValidationStarted", label: "IdValidationStarted", options: {
                customBodyRender: (value) => {
                return value && (new Date(value).toLocaleDateString('en-EN', {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                }) + ' - ' + new Date(value).toLocaleString('en-EN', {
                  timeZone: 'Europe/Sofia',
                  hour: 'numeric',
                  minute: 'numeric',
                }))}
              }},
              { name: "idValidationFinished", label: "IdValidationFinished", options: { customBodyRender: (value) => { return value.toString() }}},
              { name: "suspended", label: "Suspended", options: { customBodyRender: (value) => { return value.toString() }}},
              { name: "grants", label: "canSign", options: { customBodyRender: (value) => { return canUserSign(value).toString() }}},
            ]}
            options={{
              onRowClick: (rowData) => {
                props.history.push(`/app/user/${rowData[4]}`)
              },
              selectableRowsHideCheckboxes: false,
              count:  usersState.totalCount,
              rowsPerPage: numberOfRows,
              selectableRows: 'none',
              onChangeRowsPerPage: (_numberOfRows) => {
                setNumberOfRows(_numberOfRows)
                getUsers(
                  usersDispatch,
                  _numberOfRows,
                  '',
                  searchText,
                  'createdAt',
                  'DESC',
                  'All (default)',
                  usersState.page,
                )
              },
              onSearchChange: (_searchText) => {
                setSearchText(_searchText)
                getUsers(
                  usersDispatch,
                  numberOfRows,
                  '',
                  _searchText,
                  'createdAt',
                  'DESC',
                  'All (default)',
                  usersState.page,
                )
              },
              onChangePage: (page) => {
                if (page >= usersState.page) {
                  getUsers(
                    usersDispatch,
                    numberOfRows,
                    '',
                    searchText,
                    'createdAt',
                    'DESC',
                    'All (default)',
                    page + 1,
                  )
                }
              },
            }}
          />
        )}
        </Grid>
      </Grid>
    </>
  );
}
