import React from "react";

import API from '../core/api'

var DocumentsStateContext = React.createContext();
var DocumentsDispatchContext = React.createContext();

function documentsReducer(state, action) {
  switch (action.type) {
    case "FETCH_DOCUMENT_START":
      return {...state, errors: {}, isFetching: true }
    case "FETCH_DOCUMENTS_SUCCESS":
        const newState = {
            ...state, 
            errors: {},
            totals: action.data.totals,
            totalCount: action.data.count,
            page: action.data.page    
        }
        if (state.page < action.data.page) {
            newState.data.push(...action.data.documents)
        } else {
            newState.data = action.data.documents
        }
      return newState;
    case "FETCH_DOCUMENT_SUCCESS":
        return { ...state, isFetching: false, document: action.data };
    case "FETCH_DOCUMENTS_FAILURE":
        return { ...state, isFetching: false, data: [], errors: action.data };
    case "FETCH_DOCUMENT_FAILURE":
        return { ...state, isFetching: false, errors: action.data, document: [] };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function DocumentsProvider({ children }) {
  var [state, dispatch] = React.useReducer(documentsReducer, {
    isFetching: null,
    errors: {},
    data: [],
    totals: [],
    totalCount: 0,
    page: 0,
    document: []
  });

  return (
    <DocumentsStateContext.Provider value={state}>
      <DocumentsDispatchContext.Provider value={dispatch}>
        {children}
      </DocumentsDispatchContext.Provider>
    </DocumentsStateContext.Provider>
  );
}

function useDocumentsState() {
  var context = React.useContext(DocumentsStateContext);
  if (context === undefined) {
    throw new Error("useDocumentsState must be used within a DocumentsProvider");
  }
  return context;
}

function useDocumentsDispatch() {
  var context = React.useContext(DocumentsDispatchContext);
  if (context === undefined) {
    throw new Error("useDocumentsDispatch must be used within a DocumentsProvider");
  }
  return context;
}

const options = {
    items: [
      'All (default)',
      'Yesterday',
      'Last 7 days',
      'Last 30 days',
      'Year ago',
    ],
    values: [
      '',
      new Date().setDate(new Date().getDate() - 1),
      new Date().setDate(new Date().getDate() - 7),
      new Date().setMonth(new Date().getMonth() - 1),
      new Date().setFullYear(new Date().getFullYear() - 1),
    ],
  }

const normalizeResponse = (response, page) => {
    const documents = response.data;
    if (!response.data.length) {
        return {
        documents: documents,
        count: response.headers['x-total-count'],
        page: 0,
        totals: response.totals,
        }
    }

    return {
        documents: documents,
        count: response.headers['x-total-count'],
        page,
        totals: response.totals,
    }
}
  
const getDocuments = async (dispatch,
    perPage = 15,
    status,
    search,
    sortBy,
    sortOrder,
    filters,
    page = 0,
    archived = false) => {

    const token = localStorage.getItem('id_token')
    const tenantName = JSON.parse(localStorage.getItem('id_tenant')) && JSON.parse(localStorage.getItem('id_tenant')).name

    if ((filters || status || search || sortBy) && page === 1) {
      dispatch({ type: 'FETCH_DOCUMENT_START' })
    }
  try {
    const selectedFilter =
      filters &&
      options.values.find((el, i) => i === options.items.indexOf(filters))

    const filter = selectedFilter && `&createdAtFrom=${selectedFilter}`
    const sort = sortBy && sortOrder ? `&sort=${sortBy}&order=${sortOrder}` : ''
    const res = await API.getDocuments(
      token,
      perPage,
      status,
      search,
      sort,
      filter,
      page,
      archived,
      tenantName
    )
    const documents = normalizeResponse(res, page)

      dispatch({ type: 'FETCH_DOCUMENTS_SUCCESS', data: documents })
   } catch (err) {
      dispatch({ type: "FETCH_DOCUMENTS_FAILURE", data: err.response.data });
    }
    
  }

  
const getDocument = async (dispatch, id) => {
  const token = localStorage.getItem('id_token')
  const tenantName = JSON.parse(localStorage.getItem('id_tenant')).name

  dispatch({ type: 'FETCH_DOCUMENT_START' })

  try {
    const res = await API.getDocument(token, id, tenantName)
    const document = res.data
    dispatch({ type: 'FETCH_DOCUMENT_SUCCESS', data: document })

  } catch (err) {
    dispatch({ type: "FETCH_DOCUMENT_FAILURE", data: err.response.data });
  }   
}

// ###########################################################


export { DocumentsProvider, useDocumentsState, useDocumentsDispatch, getDocuments, getDocument };