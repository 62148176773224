import React, { useState } from "react";
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
// styles
import useStyles from "./styles";

// context
import { useTenantsState, selectTenant, useTenantsDispatch } from "../../context/TenantsContext";

// components
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";

export default function Dashboard(props) {
  var classes = useStyles();

  // global
  var { tenants, tenant, tenantData } = useTenantsState();
  var tenantsDispatch = useTenantsDispatch();

  const tenantOptions =  tenants.map(tenant => ({
    value: tenant.name,
    label: tenant.name,
    ...tenant
  }))

  // local
  const [selectedValue, setTenant] = useState(tenant || '');

  const handleChange = (event, data) => {
    const chosenTenant = tenants.find(t => t._id === data.props.id)
    setTenant(event.target.value);
    selectTenant(tenantsDispatch, chosenTenant)
  };

  return (
    <>
      <PageTitle title="Tenant" />
      
      <Box display="flex" p={4} flexDirection="column" alignItems="start">
        <Box mb={4}>

        <FormControl className={classes.formControl}>
          <InputLabel>Select tenant</InputLabel>
          <Select value={selectedValue} options={tenantOptions} onChange={handleChange}>
            {tenants.map(t => (
              <MenuItem key={t._id} id={t._id} value={t.name}>{t.name}</MenuItem>
              ))}
          </Select>
        </FormControl>
        </Box>
       {tenantData && <Box width="100%">
          <Widget disableWidgetMenu>
            <Typography variant="h4">
              Current tenant:
            </Typography>
            <Box mb={1} mt={2}>
              <Typography>
                Type: {tenantData.type}
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography>
                Name: {tenantData.name}
              </Typography>
            </Box>
            {tenantData.uin && <Box mb={1}>
              <Typography>
                Uin: {tenantData.uin}
              </Typography>
            </Box>}
            <Box mb={1}>
              <Typography>
                Created at: {' '}
                {new Date(tenantData.createdAt).toLocaleDateString('en-EN', {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                }) + ' - ' + new Date(tenantData.createdAt).toLocaleString('en-EN', {
                  timeZone: 'Europe/Sofia',
                  hour: 'numeric',
                  minute: 'numeric',
                })}
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography>
                Updated at: {' '}
                {new Date(tenantData.updatedAt).toLocaleDateString('en-EN', {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                }) + ' - ' + new Date(tenantData.updatedAt).toLocaleString('en-EN', {
                  timeZone: 'Europe/Sofia',
                  hour: 'numeric',
                  minute: 'numeric',
                })}
              </Typography>
            </Box>
          </Widget>
        </Box>
      }
      </Box>
    </>
  );
}