import React from "react";

import API from '../core/api'

var UsersStateContext = React.createContext();
var UsersDispatchContext = React.createContext();

function usersReducer(state, action) {
  switch (action.type) {
    case "FETCH_USER_START":
      return { ...state, errors: {}, isFetching: true }
    case "FETCH_USERS_SUCCESS":
        const newState = {
            ...state, 
            errors: {},
            totals: action.data.totals,
            totalCount: action.data.count,
            page: action.data.page    
        }
        if (state.page < action.data.page) {
            newState.data.push(...action.data.users)
        } else {
            newState.data = action.data.users
        }
      return newState;
    case "FETCH_USER_SUCCESS":
        return { ...state, isFetching: false, user: action.data };
    case "FETCH_USERS_FAILURE":
        return { ...state, isFetching: false, data: [], errors: action.data };
    case "FETCH_USER_FAILURE":
        return { ...state, isFetching: false, errors: action.data, user: [] };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UsersProvider({ children }) {
  var [state, dispatch] = React.useReducer(usersReducer, {
    isFetching: null,
    errors: {},
    data: [],
    totals: [],
    totalCount: 0,
    page: 0,
    user: []
  });

  return (
    <UsersStateContext.Provider value={state}>
      <UsersDispatchContext.Provider value={dispatch}>
        {children}
      </UsersDispatchContext.Provider>
    </UsersStateContext.Provider>
  );
}

function useUsersState() {
  var context = React.useContext(UsersStateContext);
  if (context === undefined) {
    throw new Error("useUsersState must be used within a UsersProvider");
  }
  return context;
}

function useUsersDispatch() {
  var context = React.useContext(UsersDispatchContext);
  if (context === undefined) {
    throw new Error("useUsersDispatch must be used within a UsersProvider");
  }
  return context;
}

const options = {
    items: [
      'All (default)',
      'Yesterday',
      'Last 7 days',
      'Last 30 days',
      'Year ago',
    ],
    values: [
      '',
      new Date().setDate(new Date().getDate() - 1),
      new Date().setDate(new Date().getDate() - 7),
      new Date().setMonth(new Date().getMonth() - 1),
      new Date().setFullYear(new Date().getFullYear() - 1),
    ],
  }

const normalizeResponse = (response, page) => {
    const users = response.data;
    if (!response.data.length) {
        return {
        users: users,
        count: response.headers['x-total-count'],
        page: 0,
        totals: response.totals,
        }
    }

    return {
        users: users,
        count: response.headers['x-total-count'],
        page,
        totals: response.totals,
    }
}
  
const getUsers = async (dispatch,
    perPage = 15,
    status,
    search,
    sortBy,
    sortOrder,
    filters,
    page = 0) => {
    const token = localStorage.getItem('id_token')
    const tenantName = JSON.parse(localStorage.getItem('id_tenant')) && JSON.parse(localStorage.getItem('id_tenant')).name

    if ((filters || status || search || sortBy) && page === 1) {
    dispatch({ type: 'FETCH_USER_START' })
  }
  try {
    const selectedFilter =
      filters &&
      options.values.find((el, i) => i === options.items.indexOf(filters))

    const filter = selectedFilter && `&createdAtFrom=${selectedFilter}`
    const sort = sortBy && sortOrder ? `&sort=${sortBy}&order=${sortOrder}` : ''
    const res = await API.getUsers(
      token,
      perPage,
      filter,
      search,
      page,
      sort,
      tenantName
    )
    const users = normalizeResponse(res, page)

    dispatch({ type: 'FETCH_USERS_SUCCESS', data: users })
   } catch (err) {
      dispatch({ type: "FETCH_USERS_FAILURE", data: err.response.data });
    }
    
  }

  
const getUser = async (dispatch, id, tenant) => {
  const token = localStorage.getItem('id_token')
  const tenantName = JSON.parse(localStorage.getItem('id_tenant')).name

  dispatch({ type: 'FETCH_USER_START' })

  try {
    const res = await API.getUser(token, id, tenant || tenantName)
    const user = res.data

    dispatch({ type: 'FETCH_USER_SUCCESS', data: user })
  } catch (err) {
    dispatch({ type: "FETCH_USER_FAILURE", data: err.response.data });
  }   
}

// ###########################################################


export { UsersProvider, useUsersState, useUsersDispatch, getUsers, getUser };