import React from "react";

import API from '../core/api'

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "LOGIN_SUCCESS_STEP_1": 
      return {
        ...state,
        errors: {},
        token: action.data.token,
        tokenLevel: action.data.tokenLevel,
        isAuthenticated: action.data.token && action.data.tokenLevel === 5,
      };
    case "LOGIN_SUCCESS_STEP_2": 
        return {
          ...state,
          errors: {},
          token: action.data.token,
          tokenLevel: action.data.tokenLevel,
          isAuthenticated: action.data.token && action.data.tokenLevel === 5,
        };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false, token: '', tokenLevel: 0 };
    case "LOGIN_FAILURE":
        return { ...state, isAuthenticated: false, errors: action.data };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    tokenLevel: localStorage.getItem("id_tokenLevel") === null ? 0 : parseInt(localStorage.getItem("id_tokenLevel")),
    isAuthenticated: !!localStorage.getItem("id_token") && (parseInt(localStorage.getItem("id_tokenLevel")) === 5),
    errors: {},
    token: undefined,
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}


// ###########################################################

const loginUser = async (dispatch, login, password, setIsLoading, setError) => {
  setError(false);
  setIsLoading(true);
  
  
  try {
    const response = await API.loginStep1({ email: login, password: password })
    
    localStorage.setItem('id_token', response.data.token)
    localStorage.setItem('id_tokenLevel', response.data.tokenLevel)
    
    setError(null)
    setIsLoading(false)
    dispatch({ type: 'LOGIN_SUCCESS_STEP_1', data: response.data })
    
  } catch (err) {
    dispatch({ type: "LOGIN_FAILURE", data: err.response.data });
    setError(true);
    setIsLoading(false);
  }
  
}


const loginUserStep2 = async (dispatch, code, history, setIsLoading, setError) => {
  setError(false);
  setIsLoading(true);
  
  try {
    const token = localStorage.getItem('id_token')
    const response = await API.loginStep2({ code: code }, token)
    
    localStorage.setItem('id_token', response.data.token)
    localStorage.setItem('id_tokenLevel', response.data.tokenLevel)
    
    setError(null)
    setIsLoading(false)
    dispatch({ type: 'LOGIN_SUCCESS_STEP_2', data: response.data })
    
    history.push('/app/dashboard')
    
  } catch (err) {
    dispatch({ type: "LOGIN_FAILURE", data: err.response.data });
    setError(true);
    setIsLoading(false);
  }
  
}

function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  localStorage.removeItem("id_tenant");
  localStorage.removeItem("id_tokenLevel");

  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}

export { UserProvider, useUserState, useUserDispatch, loginUser, loginUserStep2, signOut };