import axios from 'axios'

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}api`,
})


const config = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
}

const API = {
  loginStep1: (data) => {
    return instance.post('/auth/login-step-1', data)
  },
  loginStep2: (data, token) => {
    return instance.post('/auth/login-step-2', data, config(token))
  },
  getDocuments: (token, perPage, status, search, sort, filter, page, archived, tenantName) => {
    return instance.post(
      `/documents?perPage=${perPage}${status ? `&status=${status}` : ''}${search ? `&search=${search}` : ''}${sort}${filter}${page ? `&page=${page}` : ''}${archived ? `&archived=true` : ''}`,
      `tenant=${tenantName}`,
      config(token)
    )
  },
  getDocument: (token, id, tenantName) => {
    return instance.post(`/documents/${id}`, `tenant=${tenantName}`,config(token))
  },
  getUsers: (token, perPage, filters, search, page, sort, tenantName) => {
    return instance.post(
      `/users?perPage=${perPage}${page ? `&page=${page}` : ''}${
        filters ? filters : ''
      }${search ? '&search=' + search : ''}${sort}`,
      `tenant=${tenantName}`,
      config(token)
    )
  },
  getUser: (token, id, tenantName) => {
    return instance.post(`/users/${id}`, `tenant=${tenantName}`,config(token))
  },
  getTenants: (token) => {
    return instance.get('tenants', config(token))
  }
}

export default API
