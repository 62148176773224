import React, { useEffect } from "react";
import { Grid, Box, CircularProgress } from "@material-ui/core";
import {
  PermIdentity as PermIdentityIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
} from "@material-ui/icons";

// context
import { useDocumentsDispatch, getDocument, useDocumentsState } from "../../context/DocumentsContext";

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import Dot from "./components/Dot";

export default function Document(props) {
  var classes = useStyles();

  const id = props.match.params.id;

  // global
  var doumentsDispatch = useDocumentsDispatch();
  var { document, isFetching } = useDocumentsState();

  const goToUserPage = (userId, userTenant) => props.history.push(`/app/user/${userId}`, { tenant: userTenant })

  useEffect(() => {
    getDocument(doumentsDispatch, id)
  }, [id, doumentsDispatch])

  return (
    <>
      <PageTitle title="Document" />
      <Grid container spacing={4}>

        {
          isFetching ? <Box width="100%" display="flex" justifyContent="center">
          <CircularProgress size={76} /></Box> : (

        document && Object.keys(document).length !== 0 && document.constructor === Object && (
          <React.Fragment>

        <Grid item xs={12}>
          
        <Typography variant="h6" className={classes.textIdentifier}>
          Identifier: {document._id}
        </Typography>


        <Typography variant="h4" className={classes.textTitle}>
          {document.title}
        </Typography>
        
        <Typography variant="p">
          <Dot status={document.status} />{document.status}
        </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box variant="h6" onClick={() => goToUserPage(document.createdBy._id, document.tenant)}  mb={3} display="flex" alignItems="center">
            <PermIdentityIcon />  Owner:  <Box ml={1} display="flex" alignItems="center">
            <img className={classes.participantImage} src={document.createdBy && document.createdBy.avatar}  alt={`${document.createdBy.email} avatar`} width='20px' height='20px' />
            <Box display="flex" flexDirection="column">
            <Typography variant="p">{document.createdBy && document.createdBy.name}</Typography>
            <Typography variant="p">{document.createdBy && document.createdBy.email}</Typography>
            </Box>
          </Box>
          </Box>

          {document.participants && document.participants.length ? (
            <Box variant="h6" mb={3} display="flex" alignItems="center">
            <SupervisedUserCircleIcon mr={1} /> Participants:
          </Box>
          ): null}
          {document.participants && document.participants.length ? document.participants.map(part => {
                return (
            <>
            <Grid item spacing={2}>
                  <Box onClick={() => goToUserPage(part.user, part.tenant)} className={classes.participantBox} display="inline-flex" flexDirection="column" key={`${part.name}-participant`}>
                    <Box display="flex" alignItems="center">
                      <img className={classes.participantImage} src={part.avatar}  alt={`${part.email} avatar`} width='20px' height='20px' />
                      <Box display="flex" flexDirection="column">
                      <Typography variant="p">{part.name}</Typography>
                      <Typography variant="p">{part.email}</Typography>
                      </Box>
                    </Box>
                    <Box mt={1} mb={1}>
                      <Typography variant="p">
                        as {part.referredAs}
                      </Typography>
                    </Box>
                    <Box mb={1}>
                      <Typography variant="p">
                        from {part.tenant} tenant
                      </Typography>
                    </Box>
                    <Box>
                      {part.action.value} action - {part.action.status} - {new Date(part.action.updatedAt).toLocaleDateString('bg-Bg', {
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                      })} - {new Date(part.action.updatedAt).toLocaleString('bg-Bg', {
                        timeZone: 'Europe/Sofia',
                        hour: 'numeric',
                        minute: 'numeric',
                      })}
                    </Box>
                  </Box>
            </Grid>
            </>
           )})
          : null}
      </Grid>
      </React.Fragment>
        ))}
    
      </Grid>

    </>
  );
}
